import { useState, useEffect } from 'react'

import breakpoints from '../styles/exports/exports.module.scss'

const useBreakpoint = (key) => {
  const [isMatching, setIsMatching] = useState(false)
  const breakpoint = breakpoints[key]

  useEffect(() => {
    const getMatchMedia = () => {
      const mq = window.matchMedia(`(min-width: ${breakpoint})`)

      setIsMatching(mq.matches)
    }

    getMatchMedia()

    window.addEventListener('resize', getMatchMedia)

    return () => window.removeEventListener('resize', getMatchMedia)
  }, [breakpoint])

  return isMatching
}

export default useBreakpoint
